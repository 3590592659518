<template>
  <v-container
    class="my-4"
    :style="
      `width: ${
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? ''
          : '1200px !important'
      }; background-color: #fff;`
    "
  >
    <v-row justify="center">
      <v-col cols="12" md="12">
        <h2 class="mb-2">{{ $t("CHÍNH SÁCH BẢO MẬT THÔNG TIN") }}</h2>
        <!-- PURPOSE AND SCOPE OF INFORMATION COLLECTION -->
        <div class="text-left">
          <p class="font-weight-bold">MỤC ĐÍCH VÀ PHẠM VI THU THẬP THÔNG TIN</p>
          <p>
            Trong quá trình cung cấp dịch vụ, Conasi có thể thu thập dữ liệu cá
            nhân về Thành viên. Dữ liệu cá nhân mà website CONASI365.vn có thể
            thu thập bao gồm:
          </p>
          <ul>
            <li>Họ tên;</li>
            <li>Địa chỉ email;</li>
            <li>Địa chỉ;</li>
            <li>Tài khoản ngân hàng và thông tin thanh toán;</li>
            <li>Số điện thoại;</li>
            <li>
              Thông tin được gửi bởi hoặc liên quan đến (các) thiết bị được sử
              dụng để truy cập vào Các Dịch vụ hoặc Nền tảng của chúng tôi.
            </li>
          </ul>
          <p class="mt-4">
            Việc thu thập các thông tin nhằm mục đích phục vụ cho các giao dịch
            và hoạt động của thành viên liên quan đến website CONASI365.vn.
            Thành viên sẽ tự chịu trách nhiệm về bảo mật và lưu giữ mọi hoạt
            động sử dụng dịch vụ dưới tên đăng ký, mật khẩu và hộp thư điện tử
            của mình. Ngoài ra, thành viên có trách nhiệm thông báo kịp thời cho
            CONASI365.vn về những hành vi sử dụng trái phép, lạm dụng, vi phạm
            bảo mật, lưu giữ tên đăng ký và mật khẩu của bên thứ ba để có biện
            pháp giải quyết phù hợp.
          </p>
        </div>
        <!-- SCOPE OF INFORMATION USE -->
        <div class="text-left">
          <p class="font-weight-bold">PHẠM VI SỬ DỤNG THÔNG TIN:</p>
          <p>CONASI365.vn sử dụng thông tin thành viên cung cấp để:</p>
          <ul>
            <li>Cung cấp các dịch vụ đến Thành viên;</li>
            <li>Gửi thông tin đơn hàng;</li>
            <li>
              Gửi các thông báo về các hoạt động trao đổi thông tin giữa thành
              viên và website CONASI365.vn;
            </li>
            <li>
              Ngăn ngừa các hoạt động phá hủy tài khoản người dùng của thành
              viên hoặc các hoạt động giả mạo Thành viên;
            </li>
            <li>
              Liên lạc và giải quyết với Thành viên trong những trường hợp đặc
              biệt;
            </li>
            <li>
              Xác nhận và liên hệ với các bên có liên quan đến giao dịch tại
              website CONASI365.vn; và
            </li>
            <li>
              Trong trường hợp có yêu cầu của pháp luật: CONASI365.vn có trách
              nhiệm hợp tác cung cấp thông tin cá nhân thành viên khi có yêu cầu
              từ cơ quan tư pháp bao gồm: Viện kiểm sát, tòa án, cơ quan công an
              điều tra liên quan đến hành vi vi phạm pháp luật nào đó của thành
              viên và việc tiết lộ như vậy là cần thiết một cách hợp lý để tuân
              thủ các quy trình pháp lý. Ngoài ra, không ai có quyền xâm phạm
              vào thông tin cá nhân của thành viên.
            </li>
          </ul>
        </div>
        <!-- DURATION OF INFORMATION STORAGE -->
        <div class="text-left mt-4">
          <p class="font-weight-bold">THỜI GIAN LƯU TRỮ THÔNG TIN:</p>
          <p>
            Dữ liệu Cá nhân về Thành viên sẽ được lưu trữ cho đến khi có yêu cầu
            hủy bỏ hoặc tự Thành viên đăng nhập và thực hiện hủy bỏ. Còn lại
            trong mọi trường hợp dữ liệu cá nhân thành viên sẽ được lưu trữ, bảo
            mật trên máy chủ của website CONASI365.vn.
          </p>
        </div>
        <!-- DURATION OF INFORMATION STORAGE -->
        <div class="text-left mt-4">
          <p class="font-weight-bold">
            NHỮNG NGƯỜI HOẶC TỔ CHỨC CÓ THỂ ĐƯỢC TIẾP CẬN VỚI THÔNG TIN CÁ NHÂN
          </p>
          <p>
            Đối tượng được tiếp cận với thông tin cá nhân của thành viên thuộc
            một trong những trường hợp sau:
          </p>
          <ul>
            <li>Công Ty Cổ Phần Quản Lý Và Phát Triển Bất Động Sản Conasi;</li>
            <li>
              Các nhà cung cấp có ký hợp đồng với Công ty về việc cung cấp dịch
              vụ cho khách hàng, các đơn vị cung cấp dịch vụ như cổng thanh
              toán. Các đối tác này sẽ nhận được những thông tin theo thỏa thuận
              hợp đồng (có thể một phần hoặc toàn bộ thông tin tùy theo điều
              khoản hợp đồng) để tiến hành hỗ trợ người dùng sử dụng dịch vụ do
              Conasi cung cấp;
            </li>
            <li>
              Cơ quan nhà nước khi có yêu cầu Conasi cung cấp thông tin thành
              viên để phục vụ quá trình điều tra.
            </li>
          </ul>
        </div>
        <!-- ADDRESS OF PERSONAL INFORMATION COLLECTOR AND MANAGEMENT UNIT -->
        <div class="text-left mt-4">
          <p class="font-weight-bold">
            ĐỊA CHỈ CỦA ĐƠN VỊ THU THẬP VÀ QUẢN LÝ THÔNG TIN CÁ NHÂN:
          </p>
          <ul>
            <li>
              Tên đơn vị: Công Ty Cổ Phần Quản Lý Và Phát Triển Bất Động Sản
              Conasi
            </li>
            <li>
              Trụ sở chính: Lầu 8, 58 Nguyễn Đình Chiểu, Phường Đa Kao, Quận 1,
              TP Hồ Chí Minh.
            </li>
          </ul>
          <p class="mt-4">
            Khi cần kiểm tra về việc thu thập thông tin cá nhân trên
            CONASI365.vn, khách hàng có thể liên hệ tới đầu mối tiếp nhận thông
            tin:
          </p>
          <ul>
            <li class="font-weight-bold font-italic">
              Phòng chăm sóc khách hàng
            </li>
            <li>Email: info@conasi365.vn</li>
            <li>Điện thoại: 1900 63 68 70</li>
          </ul>
        </div>
        <!-- MEANS AND TOOLS FOR USERS TO ACCESS AND CORRECT YOUR PERSONAL DATA -->
        <div class="text-left mt-4">
          <p class="font-weight-bold">
            PHƯƠNG TIỆN VÀ CÔNG CỤ ĐỂ NGƯỜI DÙNG TIẾP CẬN VÀ CHỈNH SỬA DỮ LIỆU
            CÁ NHÂN CỦA MÌNH:
          </p>
          <p>
            Thành viên có quyền tự kiểm tra, cập nhật, điều chỉnh hoặc hủy bỏ
            thông tin cá nhân của mình bằng cách đăng nhập vào tài khoản và
            chỉnh sửa thông tin cá nhân.
          </p>
          <p>
            Thành viên có quyền gửi yêu cầu để xin khôi phục tài khoản do quên
            mật khẩu bằng cách gửi email đến địa chỉ: info@conasi365.vn
          </p>
          <p>
            Ban Quản trị CONASI365.vn sẽ hướng dẫn thành viên thực hiện các bước
            để khôi phục tài khoản.
          </p>
          <p>
            Vui lòng lưu ý Ban Quản trị sẽ không can thiệp vào mật khẩu của
            Thành viên.
          </p>
        </div>
        <!-- COMMITMENT TO PRIVACY INFORMATION -->
        <div class="text-left mt-4">
          <p class="font-weight-bold">CAM KẾT BẢO MẬT THÔNG TIN CÁ NHÂN</p>
          <p>
            Thông tin cá nhân của thành viên trên website CONASI365.vn được cam
            kết bảo mật tuyệt đối theo Chính Sách Bảo Mật Thông Tin Cá Nhân của
            website CONASI365.vn. Việc thu thập và sử dụng thông tin của mỗi
            Thành viên chỉ được thực hiện khi có sự đồng ý của Thành viên đó trừ
            những trường hợp pháp luật có quy định khác;
          </p>
          <p>
            Không sử dụng, không chuyển giao, cung cấp hay tiết lộ cho bên thứ 3
            nào về thông tin cá nhân của Thành viên khi không có sự cho phép
            đồng ý từ Thành viên;
          </p>
          <p>
            Trong trường hợp máy chủ lưu trữ thông tin bị hacker tấn công dẫn
            đến mất mát dữ liệu cá nhân Thành viên, CONASI365.vn sẽ có trách
            nhiệm thông báo vụ việc cho cơ quan chức năng điều tra xử lý kịp
            thời và thông báo cho Thành viên được biết;
          </p>
          <p>
            CONASI365.vn yêu cầu các cá nhân, tổ chức khi đăng ký/mua hàng là
            Thành viên, phải cung cấp đầy đủ thông tin cá nhân có liên quan như:
            Họ và tên, địa chỉ liên lạc, email, số chứng minh nhân dân, điện
            thoại,…., và chịu trách nhiệm về tính pháp lý của những thông tin
            trên. Chúng tôi không chịu trách nhiệm cũng như không giải quyết mọi
            khiếu nại có liên quan đến quyền lợi của Thành viên đó nếu xét thấy
            tất cả thông tin cá nhân của Thành viên đó cung cấp khi đăng ký ban
            đầu là không chính xác.
          </p>
        </div>
        <!-- MECHANISM FOR RECEIVING AND SETTLEMENT OF COMPLAINTS REGARDING MEMBER's PERSONAL INFORMATION -->
        <div class="text-left mt-4">
          <p class="font-weight-bold">
            CƠ CHẾ TIẾP NHẬN VÀ GIẢI QUYẾT KHIẾU NẠI LIÊN QUAN ĐẾN VIỆC THÔNG
            TIN CÁ NHÂN THÀNH VIÊN
          </p>
          <p>
            Thành viên có quyền khiếu nại về việc lộ thông tin cá nhân cho bên
            thứ 3 đến CONASI365.vn bằng cách gửi khiếu nại đến địa chỉ trụ sở
            của Conasi hoặc qua email: info@conasi365.vn.
          </p>
          <p>
            Conasi có trách nhiệm thực hiện các biện pháp kỹ thuật, nghiệp vụ để
            xác minh các nội dung được phản ánh.
          </p>
          <p>
            Thời gian xử lý khiếu nại/phản ánh liên quan đến thông tin cá nhân
            khách hàng tối đa là 15 ngày làm việc kể từ ngày nhận được khiếu
            nại.
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
};
</script>

<style></style>
